import { Link } from '@reach/router';
import { Menu } from 'antd';
import * as _ from 'lodash';
import React, { useState } from 'react';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Header from '../components/Header/Header';
import SEO from '../components/seo';
import '../static/landing.css';

const { SubMenu } = Menu;
const emptyUrls = {
  es: '', en: '', de: '', fr: '',
}

const Post = ({pageContext}) => {
  const { post: customPost, categories, special , urls} = pageContext;
  const jerarquizedCategories = _.groupBy(categories, 'parent');
  const [mainCategories, setMainCategories] = useState(jerarquizedCategories[0]);
  const objectifiedCategories = _.groupBy(categories, 'id');
  let breadcrumbArray = [{url: "/", text: "Home"}];
  if(customPost.categories.length > 0){
    const category = objectifiedCategories[customPost.categories[0]][0];
    breadcrumbArray.push({url: `/category/${category.slug}/`, text: `${category.name}`});
  }
  breadcrumbArray.push({url: `/`, text: `${customPost.title.rendered}`});
  console.log(urls);
  return (
    <div style={{ backgroundColor: '#f5f5f5' }}>
      <SEO
        title={`${customPost.title.rendered}`}
        description={`${customPost.title.rendered}`}
        url={(Object.keys(urls).length>0) ? urls : emptyUrls}
        lang="es"
      />
      <Header scrollclass="HeaderContainerBoatDetailScrollMobile" page="boatDetail" special={special}/>
      <Menu
        id="categories"
        style={{
          margin: '64px auto', // , width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}
        className="MenuPost"
        mode="horizontal"
      >
        {
          mainCategories.map((category) => (
            <SubMenu
              title={(
                <Link to={`/category/${category.slug}/`}>{category.name}</Link>
              )}
            >
              {
              (jerarquizedCategories[category.id])
                ? (
                  <Menu.ItemGroup>
                    {
                    jerarquizedCategories[category.id].map((subCategory) => (
                      <Menu.Item key={`/category/${subCategory.slug}`}>
                        <Link to={`/category/${subCategory.slug}/`}>{subCategory.name}</Link>
                      </Menu.Item>
                    ))
                  }
                  </Menu.ItemGroup>
                )
                : ''
              }
            </SubMenu>
          ))
        }
      </Menu>
      <div style={{
        width: '800px',
        maxWidth: '90%',
        margin: '110px auto',
        backgroundColor: '#fff',
        padding: '16px',
      }}
      >
        <Breadcrumb breadcrumb={breadcrumbArray}></Breadcrumb>
        <h1 style={{
          width: '100%',
          fontFamily: 'var(--main-font)',
          color: 'var(--main-color)',
          marginBottom: '10px',
          maxWidth: '1440px',
          textAlign: 'left',
        }}
        >
          {customPost.title.rendered}
        </h1>
        <div style={{ width: '100%', marginTop: '32px', maxWidth: '1440px' }} dangerouslySetInnerHTML={{ __html: customPost.content.rendered }} />
      </div>
    </div>
  );
};

export default Post;
